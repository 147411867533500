<template>
  <b-overlay
    :show="loadingSpinner"
    rounded="sm"
    opacity="0.6"
    spinner-small
    spinner-variant="info"
  >
    <div>
      <table class="center-table">
        <thead>
          <tr>
            <th>Competencia Compensada</th>
            <th>Período Selecionado</th>
            <th>Mês a mês</th>
            <th>Valor Original</th>
            <th>Valor Compensado</th>
            <th>Selic</th>
            <th>Processo</th>
            <th>Guia</th>
            <th>Observação</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input v-model="newItem.competenciaCompensada" type="month" />
            </td>
            <td>
              <select v-model="periodoSelecionado" @change="additemToList">
                <option
                  v-for="(item, index) in newItem.periodosCompensar"
                  :key="index"
                  :value="item.periodoApuracao"
                  :class="{ selected: selectedSet.has(item.periodoApuracao) }"
                  class="select-space"
                >
                  {{ item.periodoApuracao }}
                </option>
              </select>
            </td>
            <td>{{ formatMoney(newItem.somaValorOriginalPeriodos) }}</td>
            <td>
              <input type="number" v-model="newItem.valorOriginal" />
            </td>
            <td>
              <input type="number" v-model="newItem.valorCompensado" />
            </td>
            <td><input v-model="newItem.selic" size="5" /></td>
            <td><input v-model="newItem.processo" size="10" /></td>
            <td>
              <input v-model="newItem.guia" readonly type="month" hidden />
              {{ formatDatePTBR(this.newItem.guia) }}
            </td>
            <td><input v-model="newItem.observacao" type="text" /></td>
          </tr>
        </tbody>
      </table>
      <button @click="addItem">Adicionar</button>

      <!-- TABELA COM ITENS ADICIONADOS -->
      <table v-if="listaCompensacoes.length > 0" class="center-table2">
        <thead>
          <tr>
            <th>Competencia Compensada</th>
            <th>Período Selecionado</th>
            <th>Valor Original</th>
            <th>Valor Compensado</th>
            <th>Selic</th>
            <th>Processo</th>
            <th>Guia</th>
            <th>Data geração</th>
            <th>Observação</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="(item, index) in listaCompensacoes" :key="index"> -->
          <tr v-for="(item, index) in listaFiltrada" :key="index">
            <td>{{ formatDatePTBR(item.competencia_Compensada) }}</td>
            <td>{{ item.periodo_Credito }}</td>
            <td>{{ formatMoney(item.credito_Original) }}</td>
            <td>{{ formatMoney(item.valor_Compensado) }}</td>
            <td>{{ item.selic }}</td>
            <td>{{ item.processo }}</td>
            <td>{{ formatDatePTBR(item.guia) }}</td>
            <td>{{ formatDateComplete(item.data_Hora_Cadastro) }}</td>
            <td>{{ item.observacao }}</td>
          </tr>
        </tbody>
        <tfoot>
          <td>Totais:</td>
          <td></td>
          <td>
            {{
              formatMoney(
                listaCompensacoes[listaCompensacoes.length - 1].credito_Original
              )
            }}
          </td>
          <td>
            {{
              formatMoney(
                listaCompensacoes[listaCompensacoes.length - 1].valor_Compensado
              )
            }}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tfoot>
      </table>
      <!-- TABELA COM ITENS ADICIONADOS -->
    </div>

    <div>
      <b-modal size="sm" id="modal-dataGuia" ref="modal-dataGuia">
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="dataGuia">Data da guia:</label>
            <input
              type="month"
              id="dataGuia"
              v-model="dataGuia"
              class="form-control"
              required
            />
          </div>
          <button type="submit" class="btn btn-primary">Salvar</button>
        </form>
        <template #modal-footer>
          <b-button
            variant="btn btn-outline-danger"
            @click="FecharModalGuia(1)"
          >
            Cancelar
          </b-button>
        </template>
      </b-modal>
    </div>

    <!-- <div>
    <div class="text-center">
      <a><strong>CLIENTE - ABCDEF</strong> </a>
    </div>
    <table class="table table-responsive-sm" style="cursor: pointer">
      <thead class="bg-dark text-light">
        <th>Competencia Compensada</th>
        <th>Periodo do Crédito</th>
        <th>Crédito Original</th>
        <th>Valor Compensado</th>
        <th>Saldo</th>
        <th>Selic</th>
        <th>Processo</th>
        <th>Guia</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in listaCompensacoes" :key="index">
          <td>
            {{ formatDatePTBR(item.competencia_Compensada) }}
          </td>
          <td>
            {{ item.periodo_Credito }}
          </td>
          <td>
            {{ formatMoney(item.credito_Original) }}
          </td>
          <td>
            {{ formatMoney(item.valor_Compensado) }}
          </td>
          <td>
            {{ formatMoney(item.saldo) }}
          </td>
          <td>
            {{ item.selic }}
          </td>
          <td>
            {{ item.processo }}
          </td>
          <td>
            {{ formatDatePTBR(item.guia) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->
  </b-overlay>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import compensacaoService from "@/services/teses/compensacao.service";
import helperService from "../../../services/helper.service";

export default {
  name: "compensacao",

  components: {
    DotAnimation,
  },

  data() {
    return {
      loadingSpinner: false,
      cnpj: "",
      nCodOp: "",
      listaCompensacoes: [],
      listaCompensacao: [],
      small: true,
      searchQuery: "",
      currentPage: 1,
      pageSize: 15,
      returnTeses: false,
      compensacao: "",
      competCompensada: "",
      periodosCompensar: [],
      periodoSelecionado: "",
      periodosSelecionados: [],
      selectedSet: new Set(),
      creditoOriginal: "",
      valorCompensado: "",
      selic: "",
      processo: "",
      guia: "",
      competenciaCompensada: "",
      objCompensacao: "",
      ncodOp: "",
      cnpj: "",
      somaValorOriginalPeriodos: 0,
      items: [],
      showTable: false,
      newItem: {
        competenciaCompensada: "",
        periodosSelecionados: [],
        periodosCompensar: [],
        valorOriginal: 0,
        somaValorOriginalPeriodos: 0,
        valorCompensado: 0,
        selic: "",
        processo: "",
        guia: "",
        observacao: "",
      },
      dataGuia: null,
      tipo: 0,
    };
  },

  mounted() {
    this.nCodOp = this.$route.params.nCodOp;
    this.cnpj = this.$route.params.cnpj;
    this.tipo = this.$route.params.tipo;

    this.SalvaDataGuia(this.tipo);
    // this.getCompensacoes();
    //this.getPeriodosCompensacao();
  },

  computed: {
    listaFiltrada() {
      return this.listaCompensacoes.filter((item) => item.nCodOp !== null);
    },
  },

  methods: {
    async getCompensacoes() {
      this.loadingSpinner = true;
      await compensacaoService
        .getCompensacoes(this.nCodOp, this.cnpj, this.dataGuia)
        .then((response) => {
          console.log("response getCompensacoes==>", response);
          if (response.status == 200) {
            this.listaCompensacoes = response.data.dados;
          }
        })
        .catch((error) => {
          this.$toast.open({ message: error, type: "error", duration: 2000 });
          this.loadingSpinner = false;
          this.FecharModalGuia(0);
        });

      console.log("this.dataGuia==>", this.dataGuia);
      this.loadingSpinner = false;
      this.newItem.guia = this.dataGuia;
      this.FecharModalGuia(0);
    },

    async addItem() {
      this.loadingSpinner = true;
      console.log("aqui");
      const selectedDates = this.newItem.periodosSelecionados;

      let dataRange = "";
      if (selectedDates.length > 0) {
        selectedDates.sort();
        const firstDate = selectedDates[0];
        const lastDate = selectedDates[selectedDates.length - 1];
        dataRange = `${firstDate} a ${lastDate}`;
      }
      // this.items.push({ ...this.newItem });

      this.items.push({ ...this.newItem, periodoSelecionado: dataRange });

      await this.processarCompensacao();

      for (const key in this.newItem) {
        this.newItem[key] = "";
      }
      this.newItem.periodosSelecionados = [];
      this.selectedSet = new Set();
      this.newItem.somaValorOriginalPeriodos = 0;
      await this.getPeriodosCompensacao();
      this.$toast.open({
        message: "Incluido com sucesso",
        type: "info",
        duration: 3000,
      });
      this.loadingSpinner = false;
    },

    async getPeriodosCompensacao() {
      this.loadingSpinner = true;
      await compensacaoService
        .getPeriodosCompensacao(this.nCodOp, this.cnpj, this.dataGuia)
        .then((response) => {
          if (response.status == 200) {
            this.newItem.periodosCompensar = response.data.dados;
          }
        });
      this.loadingSpinner = false;
      // let valorAux = this.formatMoney(compensacao.saldo);
      // this.creditoOriginal = valorAux;
    },

    additemToList() {
      if (this.periodoSelecionado) {
        console.log("this.selectedSet", this.selectedSet);
        console.log("this.periodoSelecionado", this.periodoSelecionado);
        if (this.selectedSet.has(this.periodoSelecionado)) {
          console.log("3", this.periodoSelecionado);
          const index = this.newItem.periodosSelecionados.indexOf(
            this.periodoSelecionado
          );
          if (index !== -1) {
            this.newItem.periodosSelecionados.splice(index, 1);
            const periodoRemovido = this.newItem.periodosCompensar.find(
              (item) => item.periodoApuracao === this.periodoSelecionado
            );
            if (periodoRemovido) {
              this.newItem.somaValorOriginalPeriodos -=
                periodoRemovido.valorOriginal;
            }
          }
          this.selectedSet.delete(this.periodoSelecionado);
        } else {
          this.selectedSet.add(this.periodoSelecionado);
          this.newItem.periodosSelecionados.push(this.periodoSelecionado);
          const periodoAdicionado = this.newItem.periodosCompensar.find(
            (item) => item.periodoApuracao === this.periodoSelecionado
          );
          console.log("periodoAdicionado", periodoAdicionado);
          if (periodoAdicionado) {
            this.newItem.somaValorOriginalPeriodos +=
              periodoAdicionado.valorOriginal;
          }
        }
        //this.periodoSelecionado = null;
        // const label = this.$el.querySelector("label");
        // label.textContent = this.newItem.periodosSelecionados.join(", ");
      }
    },

    async processarCompensacao() {
      this.loadingSpinner = true;
      const getSelic = this.items[0].selic.replace(",", ".");
      // this.newItem.selic = parseFloat(getSelic);
      await compensacaoService
        .processarCompensacao(
          this.nCodOp,
          this.cnpj,
          this.items[0].competenciaCompensada,
          this.items[0].valorCompensado,
          this.items[0].somaValorOriginalPeriodos,
          this.items[0].valorOriginal,
          // this.items[0].selic,
          getSelic,
          this.items[0].periodosSelecionados,
          this.items[0].processo,
          this.items[0].guia,
          this.items[0].observacao
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("ok");
            this.items = [];
            this.getCompensacoes();
          }
        });
      this.loadingSpinner = false;
    },

    async SalvaDataGuia(tipo) {
      console.log("tipo==>", tipo);
      if (tipo == 0) {
        this.$refs["modal-dataGuia"].show();
      } else {
        this.dataGuia = tipo;
        console.log("dataGuia==>", this.dataGuia);
        this.getCompensacoes();
      }
    },

    async submitForm() {
      this.getCompensacoes();
    },

    FecharModalGuia(tipo) {
      if (tipo === 0) {
        this.$refs["modal-dataGuia"].hide();
        this.getPeriodosCompensacao();
      } else if (tipo === 1) {
        this.$router.push(`/teses/compensacao/`);
      }
    },

    formatMoney(number) {
      return helperService.formatMoneyPtBR(number);
    },

    formatDatePTBR(date) {
      // const formatDate = date.split("T")[0].split("-").reverse().join("/");
      // return formatDate;
      // const month = date.getMonth() + 1;
      // const year = date.getFullYear();
      // return `${String(month).padStart(2, "0")}/${year}`;
      const [year, month] = date.split("-");
      return `${month}/${year}`;
    },

    formatDateComplete(date) {
      const formatDate = date.split("T")[0].split("-").reverse().join("/");
      return formatDate;
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: lightgray;
}
.form-group {
  margin-bottom: 5px; /* Adicione o espaçamento desejado aqui */
  margin-left: 10px;
  margin-right: 10px;
}

.select-space {
  margin-left: 10px;
  margin-right: 10px; /* Adicione o espaçamento desejado entre a label e o select */
}

.center-table {
  margin: 0 auto; /* Centraliza horizontalmente */
  text-align: center; /* Centraliza o conteúdo nas células */
  width: 80%; /* Ajuste a largura da tabela conforme necessário */
}

.center-table2 {
  border-collapse: collapse;
  width: 100%;
}

.center-table2 td,
.center-table2 th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.center-table2 tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>
